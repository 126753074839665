<template>
    <div class="mx-2">
        <v-btn large @click="fullscreen ? exitFullScreen() : fullScreen()" icon class="mr-2">
            <!-- <v-icon>{{fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'}}</v-icon> -->
            <v-icon>{{fullscreen ? 'zoom_out_map' : 'crop_free'}}</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fullscreen: false,
        };
    },
    methods: {
        fullScreen() {
            document.documentElement
                .requestFullscreen()
                .then(() => (this.fullscreen = true));
        },
        exitFullScreen() {
            document.exitFullscreen().then(() => (this.fullscreen = false));
        },
    },
};
</script>
