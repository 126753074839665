<template>
    <div>
        <v-menu :nudge-width="100" offset-y v-if="userData" flat>
            <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                    <div
                        v-if="!$vuetify.breakpoint.mobile"
                        class="pl-2"
                    >{{ userData.userData.fullName }}</div>
                    <!-- <v-avatar size="36" color="primary" class="white--text font-weight-bold">
                        <span>{{ userData.userData.fullName.split(' ')[0].charAt(0) }}</span>
                        <span>{{ userData.userData.fullName.split(' ')[1].charAt(0) }}</span>
                    </v-avatar>-->
                    <v-avatar v-if="userData.userData.imageUrl" size="36">
                        <v-img :src="userData.userData.imageUrl" alt="zaid" width="90"></v-img>
                    </v-avatar>
                    <v-avatar v-else size="36" color="primary" class="white--text font-weight-bold">
                        <span>{{ userData.userData.fullName.split(' ')[0].charAt(0) }}</span>
                        <span>{{ userData.userData.fullName.split(' ')[1] ? userData.userData.fullName.split(' ')[1].charAt(0) : "" }}</span>
                    </v-avatar>
                </div>
            </template>

            <v-card flat class="text-center pa-10">
                <v-img class="mx-auto" src="@/assets/img/user.svg" alt="zaid" width="90"></v-img>
                <h3 class="text-center mt-3">{{ userData.userData.fullName }}</h3>
                <v-card-text>
                    <div class="grey--text mt-n2">{{ userData.userData.userName }}</div>
                    <div class="grey--text mt-n2">{{ userData.userData.email }}</div>
                    <v-btn to="/myProfile" text block color="info" class="my-2">ادارة حسابك الشخصي</v-btn>
                </v-card-text>

                <v-divider></v-divider>

                <v-btn block outlined color="red" @click="logOut()">تسجيل خروج</v-btn>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            dailogchangePassword: false,
        };
    },

    computed: {
        ...mapState("auth", ["userData"]),
    },

    methods: {
        async logOut() {
            this.$eventBus.$emit(`unsubscribeFromTopic`);
            this.$store.dispatch("auth/logout");
            this.$store.dispatch("toastification/setToast", {
                message: `تم تسجيل الخروج`,
            });
            this.$router.push("/login");
        },

        async unsubscribeFromTopic() {        
            this.$messaging()
                .unsubscribeFromTopic("registrationTokens", "admin")
                .then(function (response) {
                    // See the MessagingTopicManagementResponse reference documentation
                    // for the contents of response.
                     console.log(
                        "Successfully unsubscribed from topic:",
                        response
                    );
                })
                .catch(function (error) {
                     console.log("Error unsubscribing from topic:", error);
                });
        },
    },
};
</script>
<style lang=""></style>
